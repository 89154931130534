export function renderDownloadThrobber(
  canvas: HTMLCanvasElement | null,
  {
    drawCircle = true,
    drawDurationMs = 3300,
    color = "#ddd",
    sizePx = 64,
  }: {
    drawCircle?: boolean;
    drawDurationMs?: number;
    color?: string;
    sizePx?: number;
  } = {},
): void {
  const ctx = canvas?.getContext("2d");

  if (!canvas || !ctx) {
    return;
  }

  const pixelRatio = window.devicePixelRatio ?? 1;

  // initial application of styling of the canvas
  canvas.style.cssText = `
    position: absolute;
    top: calc(50% - ${sizePx / 2}px);
    left: calc(50% - ${sizePx / 2}px);
    height: ${sizePx}px;
    width: ${sizePx};
  `;

  canvas.width = sizePx * pixelRatio;
  canvas.height = sizePx * pixelRatio;

  // configure canvas rendering style
  ctx.fillStyle = color;
  ctx.strokeStyle = color;
  ctx.lineWidth = (sizePx / BASE_SIZE_PX) * pixelRatio;

  // prepare configurations
  const startAnimationAt =
    Date.now() + (drawCircle ? (drawDurationMs / 33) * 8 : 0);

  const onAnimationFrame = () => {
    const iteration = Math.ceil(
      (Date.now() - startAnimationAt) / drawDurationMs,
    );
    const p =
      ((Date.now() - startAnimationAt) % drawDurationMs) / drawDurationMs;

    drawThrobber(canvas, ctx, {
      p,
      iteration,
      sizePx: sizePx * pixelRatio,
      drawingRelativePx: (sizePx * pixelRatio) / BASE_SIZE_PX,
    });

    if (canvas.isConnected) {
      requestAnimationFrame(onAnimationFrame);
    }
  };

  onAnimationFrame();
}

function drawThrobber(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  config: {
    p: number;
    iteration: number;
    drawingRelativePx: number;
    sizePx: number;
  },
) {
  ctx.save();

  try {
    canvas.style.transform = "";

    for (const segment of ANIMATION_SEGMENTS) {
      if (config.p >= segment.from && config.p <= segment.to) {
        const segmentP =
          (config.p - segment.from) / (segment.to - segment.from);

        switch (segment.type) {
          case "drawCircle":
            drawCircle(ctx, segmentP, config);
            return;

          case "drawArrow":
            drawArrow(ctx, segmentP, config);
            return;

          case "drawShapes":
            drawShapes(ctx, segmentP, config);
            return;

          case "rotate":
            rotate(canvas, ctx, segmentP, config);
            return;
        }
      }
    }
  } finally {
    ctx.restore();
  }
}

function drawCircle(
  ctx: CanvasRenderingContext2D,
  p: number,
  config: {
    sizePx: number;
    drawingRelativePx: number;
  },
) {
  ctx.clearRect(0, 0, config.sizePx, config.sizePx);
  ctx.beginPath();
  ctx.arc(
    config.sizePx / 2,
    config.sizePx / 2,
    config.sizePx / 2 - config.drawingRelativePx,
    Math.PI * -0.5,
    Math.PI * -0.5 + Math.PI * 2 * easeInOutQuart(p),
  );
  ctx.stroke();
}

function drawArrow(
  ctx: CanvasRenderingContext2D,
  p: number,
  config: {
    sizePx: number;
    drawingRelativePx: number;
  },
) {
  // draw a full circle around the arrow
  drawCircle(ctx, 1, config);

  // create a circular clipping mask, so the arrow doesn't exceed the bounds of
  // the circle
  ctx.beginPath();
  ctx.arc(
    config.sizePx / 2,
    config.sizePx / 2,
    config.sizePx / 2 - config.drawingRelativePx,
    0,
    Math.PI * 2,
  );
  ctx.clip();

  // calculate offset of the arrow based on the timing of the
  const offsetFrom = -13 * config.drawingRelativePx;
  const offsetTo = BASE_SIZE_PX * config.drawingRelativePx;

  let offset = offsetFrom;

  if (p < 0.35) {
    offset =
      offsetFrom + ((offsetTo - offsetFrom) / 2) * easeInOutQuart(p / 0.35);
  } else if (p < 0.65) {
    offset = offsetFrom + (offsetTo - offsetFrom) / 2;
  } else {
    offset =
      offsetFrom +
      (offsetTo - offsetFrom) / 2 +
      ((offsetTo - offsetFrom) / 2) * easeInOutQuart((p - 0.65) / 0.35);
  }

  // draw arrow at the designated offset
  ctx.beginPath();
  ctx.moveTo(config.drawingRelativePx * 13, offset);
  ctx.lineTo(
    config.drawingRelativePx * 13,
    config.drawingRelativePx * 7 + offset,
  );
  ctx.lineTo(
    config.drawingRelativePx * 10,
    config.drawingRelativePx * 7 + offset,
  );
  ctx.lineTo(
    config.drawingRelativePx * 16,
    config.drawingRelativePx * 13 + offset,
  );
  ctx.lineTo(
    config.drawingRelativePx * 22,
    config.drawingRelativePx * 7 + offset,
  );
  ctx.lineTo(
    config.drawingRelativePx * 19,
    config.drawingRelativePx * 7 + offset,
  );
  ctx.lineTo(config.drawingRelativePx * 19, offset);
  ctx.lineTo(config.drawingRelativePx * 13, offset);
  ctx.closePath();
  ctx.fill();
}

function drawShapes(
  ctx: CanvasRenderingContext2D,
  p: number,
  config: {
    sizePx: number;
    drawingRelativePx: number;
  },
) {
  // draw a full circle around the shapes
  drawCircle(ctx, 1, config);

  for (const shape of ANIMATION_SHAPES) {
    const shapeP = Math.max(
      0,
      Math.min((easeInOutQuart(p) - shape.delay) / shape.duration, 1),
    );

    switch (shape.type) {
      case "rect":
        drawRect(ctx, shape.x1, shape.y1, shape.x2, shape.y2, shapeP, config);
        continue;

      case "line":
        drawLine(ctx, shape.x1, shape.y1, shape.x2, shape.y2, shapeP, config);
        continue;
    }
  }
}

function rotate(
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  p: number,
  config: {
    iteration: number;
    sizePx: number;
    drawingRelativePx: number;
  },
) {
  // draw a full circle and set of shapes to fill the canvas while rotating
  drawCircle(ctx, 1, config);

  if (p < 0.5) {
    drawShapes(ctx, 1, config);
  }

  const axis = config.iteration % 2 ? "rotateY" : "rotateX";
  const rotation = Math.round(easeInOutQuart(p) * 180);

  canvas.style.transform = `${axis}(${rotation}deg)`;
}

function drawRect(
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  p: number,
  config: {
    drawingRelativePx: number;
  },
): void {
  const x = x1 * config.drawingRelativePx;
  const y = y1 * config.drawingRelativePx;
  const w = (x2 - x1) * config.drawingRelativePx;
  const h = (y2 - y1) * config.drawingRelativePx;

  ctx.globalAlpha = p;
  ctx.fillRect(x + (w * (1 - p)) / 2, y + (h * (1 - p)) / 2, w * p, h * p);
  ctx.globalAlpha = 1;
}

function drawLine(
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  p: number,
  config: {
    drawingRelativePx: number;
  },
): void {
  ctx.beginPath();
  ctx.moveTo(
    x1 * config.drawingRelativePx,
    (y1 + 0.5) * config.drawingRelativePx,
  );
  ctx.lineTo(
    (x1 + (x2 - x1) * p) * config.drawingRelativePx,
    (y1 + 0.5 + (y2 - y1) * p) * config.drawingRelativePx,
  );
  ctx.stroke();
}

const BASE_SIZE_PX = 32;

const ANIMATION_SEGMENTS = [
  {
    from: -8 / 33,
    to: 0,
    type: "drawCircle",
  },
  {
    from: 0,
    to: 11 / 33,
    type: "drawArrow",
  },
  {
    from: 12 / 33,
    to: 24 / 33,
    type: "drawShapes",
  },
  {
    from: 25 / 33,
    to: 32 / 33,
    type: "rotate",
  },
] as const;

const ANIMATION_SHAPES = [
  {
    type: "rect",
    x1: 7,
    y1: 7,
    x2: 14,
    y2: 14,
    delay: 0,
    duration: 0.625,
  },
  {
    type: "line",
    x1: 15,
    x2: 22,
    y1: 7,
    y2: 7,
    delay: 0.15,
    duration: 0.675,
  },
  {
    type: "line",
    x1: 15,
    x2: 25,
    y1: 9,
    y2: 9,
    delay: 0.175,
    duration: 0.8,
  },
  {
    type: "line",
    x1: 15,
    x2: 26,
    y1: 11,
    y2: 11,
    delay: 0.125,
    duration: 0.575,
  },
  {
    type: "line",
    x1: 15,
    x2: 20,
    y1: 13,
    y2: 13,
    delay: 0.15,
    duration: 0.6,
  },
  {
    type: "line",
    x1: 7,
    x2: 25,
    y1: 15,
    y2: 15,
    delay: 0,
    duration: 1,
  },
  {
    type: "line",
    x1: 7,
    x2: 26,
    y1: 17,
    y2: 17,
    delay: 0,
    duration: 0.65,
  },
  {
    type: "line",
    x1: 7,
    x2: 17,
    y1: 19,
    y2: 19,
    delay: 0,
    duration: 0.775,
  },
  {
    type: "line",
    x1: 7,
    x2: 25,
    y1: 21,
    y2: 21,
    delay: 0,
    duration: 1,
  },
  {
    type: "line",
    x1: 7,
    x2: 24,
    y1: 23,
    y2: 23,
    delay: 0,
    duration: 0.825,
  },
  {
    type: "line",
    x1: 7,
    x2: 19,
    y1: 25,
    y2: 25,
    delay: 0,
    duration: 0.875,
  },
] as const;

function easeInOutQuart(argP: number): number {
  function easeInQuart(p: number): number {
    return Math.pow(p, 4);
  }

  const p = Math.max(0, Math.min(1, argP));

  return p < 0.5 ? easeInQuart(p * 2) / 2 : 1 - easeInQuart(p * -2 + 2) / 2;
}

/**
 * Attempt to initialize the download throbber as soon as this script has been
 * loaded.
 */
renderDownloadThrobber(document.querySelector("canvas#throbber"));
